export const setField = field => {
  const name = field.target.name
  const value = field.target.value 
  return { 
    type: 'SET_FIELD',
    payload: {
      name,
      value
    } 
  }
};


export const setHomeCityState = (data) => {
  return {
    type: 'SET_HOME_CITY_STATE',
    payload: {
      home_city: data.places[0]['place name'],
      home_state: data.places[0]['state abbreviation'],
      us_state: data.places[0]['state']
    }
  }
}

export const clearHomeCityState = () => {
  return {
    type: 'CLEAR_HOME_CITY_STATE',
    payload: {
      home_city: "",
      home_state: ""
    }
  }
}

export const setAffParams = ({affid, sub_id1, sub_id2, sub_id3}) => {
  return {
    type: 'SET_AFF_PARAMS',
    payload: {
      sub_id1: sub_id1 || "s1",
      sub_id2: sub_id2 || "s2",
      sub_id3: sub_id3 || "s3",
      client_name: affid || `${process.env.GATSBY_AFFID}`,
      affid: affid || `${process.env.GATSBY_AFFID}`,
    }
  }
}

export const setIpAddress = (ip_address) => {
  return {
    type: 'SET_IP_ADDRESS',
    payload: {
      ip_address
    }
  }
}

export const setUniversalLeadId = (universal_leadid) => {
  return {
    type: 'SET_UNIVERSAL_LEAD_ID',
    payload: {
      universal_leadid
    }
  }
}

export const setUserAgent = (user_agent) => {
  return {
    type: 'SET_USER_AGENT',
    payload: {
      user_agent
    }
  }
}

export const nextPage = () => {
  return {
    type: 'NEXT_PAGE'
  }
}

export const prevPage = () => {
  return {
    type: 'PREV_PAGE'
  }
}

export const setPercent = (percent) => {
  return {
    type: 'PERCENT',
    payload: {
      percent
    }
  }
}

export const setTitle = (title) => {
  return {
    type: 'TITLE',
    payload: {
      title
    }
  }
}

export const setText = (text) => {
  return {
    type: 'TEXT',
    payload: {
      text
    }
  }
}

export const setPageNumber = (pageNumber) => {
  return {
    type: 'PAGE_NUMBER',
    payload: {
      pageNumber
    }
  }
}