import React from 'react'
import { Link } from 'gatsby'

const Footer = ( ) => (
  <footer>
    <div className="container">
      <div className="flex-row">
        <div className="flex-small">
          <div className="copyright">
            <span>© GetMyHealthcare 2019. All rights reserved.</span>
          </div>
          <div className="footer-links">
            <Link to="/privacy">Privacy Policy</Link>  |  <Link to="/terms-of-use">Terms of Use</Link>  |  <Link to="/" >About Us</Link>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer;