/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, {Component} from "react"
import {connect} from 'react-redux'
import { setAffParams, setIpAddress, setUserAgent, setUniversalLeadId } from '../state/action'
import Footer from "./footer"
import Header from "./header"
class Layout extends Component {

  searchToObject = (search) => {
    // window check is necessary for production build step
      return search.substring(1).split('&').reduce((result, value) => {
				const parts = value.split('=');
				if (parts[0]) result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
				return result;
			}, {});
  }

  componentDidMount(){
    let self = this
    this.props.setUniversalLeadId(document.getElementById('leadid_token').value)
    this.props.setUserAgent(navigator.userAgent)
    const params = this.searchToObject(this.props.location.search)
    this.props.setAffParams(params)

    fetch('https://ipapi.co/json/')
    .then(
      function(response) {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          self.props.setIpAddress("127.0.0.1")
          return;
        }
        // Examine the text in the response
        response.json().then(function(data) {
          console.log("IP ADDRESS----->", data.ip)
          self.props.setIpAddress(data.ip)
          }
        );
      }
    )
    .catch(function(err) {
      self.props.setIpAddress("127.0.0.1") 
    });

  }

  render () {
    return (
      <>
        <Header />
          {this.props.children}
        <Footer />
      </>
    )
  }

}  

const mapDispatchToProps = dispatch => {
  return {
    setAffParams: params => dispatch(setAffParams(params)),
    setIpAddress: ip_address => dispatch(setIpAddress(ip_address)),
    setUniversalLeadId: universal_leadid => dispatch(setUniversalLeadId(universal_leadid)),
    setUserAgent: user_agent => dispatch(setUserAgent(user_agent)),
  }
}

export default connect( null, mapDispatchToProps )( Layout )
