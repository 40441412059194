import React from "react"
import logoImg from "../images/getmyhealthcare-logo.svg"
// import agentImg from "../images/agent-header.png"
import { Link } from 'gatsby'

const Header = () => (
  <header>
    <div className="container">
      <div className="flex-row">
        <div className="flex-small">
          <Link to="/" >
            <img src={logoImg} alt="logo" />
          </Link>
          
        </div>
        <div className="flex-small agent-column">
          <a className="phone-button" href="tel:18443259235">Call Now</a>
          <ul className="agent-header-list">
            <li>Free Expert Advice</li>
            <li>
                <a className="phone-link" href="tel:18443259235">(844) 325-9235</a>
            </li>
            <li>
              <span className="expert-agent">Expert Agents Available Now</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
)

export default Header
